<template>
    <content-wrapper
        :title="$trans(appsLabel)"
    >
        <v-row no-gutters>
            <template
                v-for="(item, index) in appsSettings"
            >
                <v-col
                    v-if="item.component || ((item.access && $auth.hasAccess(item.access)) || !item.access)"
                    :key="index"
                    v-bind="colBindings"
                >
                    <component
                        v-if="item.component"
                        :is="item.component"
                        :key="index"
                        class="mb-4"
                    />
                    <settings-list-group
                        v-else-if="(item.access && $auth.hasAccess(item.access)) || !item.access"
                        :key="index"
                        v-bind="item"
                        class="mb-4"
                    />
                </v-col>
            </template>
        </v-row>
    </content-wrapper>
</template>

<script>
import appsSettings from '@apps/settings/appsSettingsProvider'
import ContentWrapper from '@/components/ContentWrapper'

export default {
    name: 'SettingsPage',
    components: {
        /* eslint-disable-next-line */
        SettingsForm: () => import('@apps/settings/components/SettingsForm'),
        SettingsListGroup: () => import('@apps/settings/components/SetingsList/SettingsListGroup'),
        ContentWrapper
    },
    computed: {
        appsLabel() {
            return this.$route.meta.label || 'Untitled'
        },
        appsSettings() {
            const target = this.$route.meta.target
            return target && appsSettings[target] ? appsSettings[target].settings : []
        },
        colBindings() {
            const bindings = {
                // class: [ 'py-1' ],
                cols: 12
            }

            // if (this.appsSettings.length > 1) {
            //     bindings.md = 6
            // }

            return bindings
        }
    }
}
</script>
